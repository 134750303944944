var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mod-list row no-gutters" },
    [
      _vm.mods.length
        ? _vm._l(_vm.mods, function (mod) {
            return _c(
              "div",
              { staticClass: "mod-list__mod block-xs--xs-bottom col-12" },
              [
                _c("div", { staticClass: "mod-list__mod-inner row" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(_vm._s(mod.optionDescription)),
                  ]),
                ]),
              ]
            )
          })
        : [_vm._m(0)],
      _c(
        "div",
        { staticClass: "mod-list__add col-12" },
        [
          _c(
            "text-button",
            {
              attrs: { type: "add" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("add")
                },
              },
            },
            [
              _vm._v(" Add "),
              _vm.mods.length ? [_vm._v("another")] : _vm._e(),
              _vm._v(" Mod "),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block-xs--sm-bottom col-12" }, [
      _c("p", [_vm._v("Please click below to add mods.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modGroup
    ? _c(
        "div",
        {
          staticClass: "store-mod-group-detail row no-gutters",
          attrs: { id: "store-mod-group-detail" },
        },
        [
          _c("context-title", {
            staticClass: "col-12",
            attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
          }),
          _c("line-item", { staticClass: "col-12" }, [
            _c("span", { staticClass: "edit-bar" }),
          ]),
          _c(
            "key-val-line",
            { attrs: { prop: "Enabled" } },
            [
              _c("toggle", {
                attrs: { slider: true },
                model: {
                  value: _vm.modGroup.enabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.modGroup, "enabled", $$v)
                  },
                  expression: "modGroup.enabled",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Name" } },
            [
              _c("form-input", {
                model: {
                  value: _vm.modGroup.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.modGroup, "title", $$v)
                  },
                  expression: "modGroup.title",
                },
              }),
            ],
            1
          ),
          _vm.mods.length
            ? _c("key-val-line", { attrs: { prop: "Mods" } }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("mods", {
                        attrs: { mods: _vm.mods, addAction: _vm.onAddMod },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.modGroup.id
            ? _c("line-item", { staticClass: "col-12 height-xs--12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "row no-gutters height-xs--inherit align-items-center",
                  },
                  [
                    _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        attrs: { type: "delete" },
                        nativeOn: {
                          click: function ($event) {
                            _vm.deleteModalActive = true
                          },
                        },
                      },
                      [_vm._v("Delete group")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.deleteModalActive
            ? _c(
                "editing-modal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "alert-dialog",
                    {
                      attrs: {
                        onExit: () => (_vm.deleteModalActive = false),
                        actions: _vm.deleteActions,
                        title: "Delete Mod Group?",
                      },
                    },
                    [
                      _vm._v(
                        " This will delete " +
                          _vm._s(_vm.modGroup.title) +
                          ". Are you sure? You can also disable it instead. "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("unload-confirm", {
            attrs: {
              onExit: _vm.handleLeaveModalExit,
              leaveAction: _vm.leaveConfirmationAction,
              actions: _vm.leaveActions,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
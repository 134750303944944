<template>
    <div 
        v-if="modGroup" 
        id="store-mod-group-detail" 
        class="store-mod-group-detail row no-gutters">
        <context-title 
            :title="title" 
            :passedActions="titleBarActions" 
            class="col-12" />

        <line-item class="col-12"><span class="edit-bar"/></line-item>

        <key-val-line prop="Enabled">
            <toggle 
                v-model="modGroup.enabled" 
                :slider="true" />
        </key-val-line>

        <key-val-line prop="Name">
            <form-input v-model="modGroup.title" />
        </key-val-line>

        <key-val-line 
            v-if="mods.length" 
            prop="Mods">
            <div class="row no-gutters">
                <div class="col-4">
                    <mods 
                        :mods="mods" 
                        :addAction="onAddMod" />
                </div>
            </div>
        </key-val-line>

        <line-item 
            v-if="modGroup.id" 
            class="col-12 height-xs--12">
            <div class="row no-gutters height-xs--inherit align-items-center">
                <text-button
                    class="block-xs--sm-left"
                    type="delete"
                    @click.native="deleteModalActive = true">Delete group</text-button>
            </div>
        </line-item>

        <editing-modal 
            v-if="deleteModalActive" 
            size="sm">
            <alert-dialog
                :onExit="() => deleteModalActive = false"
                :actions="deleteActions"
                title="Delete Mod Group?"
            >
                This will delete {{ modGroup.title }}. Are you sure? You can also disable it instead.
            </alert-dialog>
        </editing-modal>

        <unload-confirm
            :onExit="handleLeaveModalExit"
            :leaveAction="leaveConfirmationAction"
            :actions="leaveActions" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { pageUnloadController } from '../mixins.js'
import contextTitle from 'components/context_title.vue'
import keyValLine from 'components/key_val_line.vue'
import toggle from 'components/cleverly/Toggle.vue'
import formInput from 'components/cleverly/FormInput.vue'
import lineItem from 'components/line_item.vue'
import mods from 'components/mods/mods.vue'
import textButton from 'components/text_button.vue'
import editingModal from 'components/editing_modal.vue'
import alertDialog from 'components/alert_dialog.vue'

export default {
    name: 'StoreModGroupEdit',
    mixins: [pageUnloadController],
    data() {
        return {
            deleteModalActive: false,
            modGroup: false,
            titleBarActions: [
                {
                    type: 'cancel',
                    display: 'Cancel',
                    run: () => {
                        if (!this.modGroup.id) {
                            this.$router.push({name: 'store-mod-index'})
                        } else {
                            this.$router.push({name: 'store-mod-group-detail', params: {group: this.modGroup.id}})
                        }
                    },
                },
                {
                    type: 'proceed',
                    display: 'Save',
                    run: () => {
                        if (!this.modGroup.id) {
                            this.$router.push({name: 'store-mod-index'})
                        }

                        this.saveModGroup(this.modGroup)
                    },
                },
            ],
            deleteActions: [
                {
                    type: 'disable',
                    display: 'Disable',
                    run: this.disableSelf,
                },
                {
                    type: 'remove-red',
                    display: 'Delete',
                    run: this.deleteSelf,
                },
            ],
        }
    },
    computed: {
        title() {
            return [
                {to: {name: 'store-mod-index'}, label: 'Mod Group'},
                (this.modGroup.title || 'New Mod Group'),
            ]
        },
        mods() {
            return this.allMods().filter(mod => mod.modGroupId === Number(this.$route.params.group))
        },
        ...mapGetters({
            modGroups: 'modGroups',
            allMods: 'mods',
        }),
    },
    methods: {
        onAddMod() {
            this.toast('Needs API')
        },
        disableSelf() {
            this.deleteModalActive = false
            this.modGroup.enabled = false
            this.toast('This mod group has been disabled.')
        },
        deleteSelf() {
            this.deleteModalActive = false
            this.$router.push({name: 'store-mod-index'})
            this.deleteModGroup(this.modGroup.id)
            this.toast('Mod group deleted.')
        },
        ...mapActions([
            'saveModGroup',
            'deleteModGroup',
        ]),
    },
    components: {
        contextTitle,
        keyValLine,
        toggle,
        formInput,
        lineItem,
        mods,
        textButton,
        editingModal,
        alertDialog,
    },
    watch: {
        modGroup: {
            handler() {
                this.handleChange()
            },
            deep: true,
        },
    },
    mounted() {
        if (this.$route.params.group) {
            const group = this.modGroups().filter(group => group.id === this.$route.params.group)[0]

            this.modGroup = group
        } else {
            this.modGroup = {
                enabled: true,
                title: '',
                mods: [],
            }
        }
    },
}
</script>

<style lang="scss">
</style>

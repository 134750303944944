<template>
    <div class="mod-list row no-gutters">
        <template v-if="mods.length">
            <div 
            class="mod-list__mod block-xs--xs-bottom col-12"
            v-for="mod in mods"
            >
                <div class="mod-list__mod-inner row">
                    <div class="col">{{ mod.optionDescription }}</div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="block-xs--sm-bottom col-12">
                <p>Please click below to add mods.</p>
            </div>
        </template>

        <div class="mod-list__add col-12">
            <text-button type="add" @click.native="$emit('add')">
                Add <template v-if="mods.length">another</template> Mod
            </text-button>
        </div>
    </div>
</template>

<script>
import textButton from '../text_button.vue'

export default {
    name: 'Mods',
    props: {
        mods: {
            type: Array,
            required: true,
        },
    },
    components: {
        textButton,
    },
}
</script>

<style lang="scss">
@import '~scss/variables';
@import '~scss/mixins';

.mod-list {
    &__mod {
        background-color: #fff;
    }

    &__mod-inner {
        padding: spacing(xxs);
    }
}

</style>
